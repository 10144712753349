.AlignFile{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .show_gallery {
    width: 760px;
    position: relative;
    margin: 0;
}
.PopUp .show_gallery .div_data {
    z-index: 2;
}
.PopUp .show_gallery .show_img{
    max-width: 100%;
    max-height: 600px;
}
.PopUp .show_gallery .div_video{
    width: -webkit-fill-available;
    height: 400px;
}

.PopUp .show_gallery .div_arrow{
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 4;
}
/* .PopUp .show_gallery .div_arrow .arrow:nth-child(1){
    padding-left: 10%;
}
.PopUp .show_gallery .div_arrow .arrow:nth-child(2){
    padding-right: 10%;
} */
.PopUp .show_gallery .div_arrow .space_white{
    width: 760px;
}
.PopUp .show_gallery .div_arrow .arrow .icons{
    width: 100px;
    height: 100px;
}
.PopUp .show_gallery .div_arrow .arrow .icons_left{
    transform: rotate(180deg);    
}
.PopUp .show_gallery .div_img{
    position: relative;
    width: 100%;
    height: 100%;
}
.PopUp .show_gallery .div_square{
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    position: absolute;
    cursor: pointer;
}
.PopUp .show_gallery .div_square .point_selected{
    width: 8px;
    height: 8px;
    background-color: #f00000;
    position: absolute;
    border-radius: 8px;
}
.PopUp .show_gallery .div_square .point_selected .show_number_point{
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -40px;
    left: -6px;
    color: #ffffff;
}

.PopUp .show_gallery .add_point{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    color: #ffffff;
    font-size: 16px;
}
.PopUp .show_gallery .add_point .btn{
    background-color: #7c9f06;
    padding: 6px 20px;
    width: max-content;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'inter';
    font-size: 14px;
}
.PopUp .show_gallery .add_point .save_point{
    background-color: #E51D4D;
}