.Page_Disassemble_Details{
    padding-bottom: 70px;
}
.Page_Disassemble_Details .type_file{
    width: calc(50% - 8px);
    text-align: center;
    background-color: #7c9f06;
    color: #ffffff;
    text-transform: uppercase;
    padding: 6px 0px;
    border-radius: 4px;
    cursor: pointer;
}
.Page_Disassemble_Details .type_file:hover, .Page_Disassemble_Details .type_file_active{
    background-color: #0b711c;
}

.Page_Disassemble_Details .list_input_data{
    justify-content: space-between;
}
.Page_Disassemble_Details .show_list_file .div_add_img{
    justify-content: flex-end;
    margin-bottom: 10px;
}
.Page_Disassemble_Details .show_list_file .delete_all{
    color: #ededed;
    background-color: #f00000;
    padding: 8px 20px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}
.Page_Disassemble_Details .list_input_data .div_list_file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #2d3c53;
    border-radius: 6px;
    flex-grow: 1;
}
.Page_Disassemble_Details .list_input_data .div_list_file .numb_img{
    background-color: #2d3c53;
    height: 36px;
    width: 42px;
    min-width: 42px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}
.Page_Disassemble_Details .list_input_data .div_list_file .name_img{
    flex-grow: 1;
    padding: 0px 10px;
    min-width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Page_Disassemble_Details .list_input_data .div_list_file .delete_img{
    padding-right: 5px;
}

.Page_Disassemble_Details .list_point{
    align-items: flex-start;

    border: 1px solid #e2e8f0;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 6px;
}
.Page_Disassemble_Details .list_point:nth-last-child(-n+1) {
    margin-bottom: 0;
}
.Page_Disassemble_Details .list_input_data .div_img{
    width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 700;
}
.Page_Disassemble_Details .list_input_data .div_img .img_example{
    width: 100%;
    height: auto;
    display: flex;
}
.Page_Disassemble_Details .list_input_data .div_point{
    width: calc(100% - 510px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.Page_Disassemble_Details .list_input_data .div_point .show_point{
    width: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
}
.Page_Disassemble_Details .list_input_data .div_point .show_point .number_point{
    border-bottom: 1px solid #e2e8f0;
    padding: 10px 10px 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Page_Disassemble_Details .list_input_data .div_point .show_point .list_opt_point{
    padding: 0px 10px;
}

.Page_Disassemble_Details .list_opt_point .list_input_data{
    justify-content: flex-start;
}