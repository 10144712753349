html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6c757d;
    font-family: 'Poppins';
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 1px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.container{
    max-width: 1200px;
    margin: 0% auto;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.list_opt {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 6px;
}
.add_more{
    color: #ffffff;
    font-size: 24px;
    line-height: 0.8;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.cursor_pointer{
    cursor: pointer;
}
.close_select{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}
ul {
    list-style: disc !important;
    padding-left: 18px;
}
ul li {
    margin-bottom: 20px;
}
.space_top{
    margin-top: 20px;
}
.space_div {
    flex-grow: 1;
}
.div_color{
    width: 30px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #000000;
}
.div_video {
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
    border: none;
}

.icons_project{
    width: 24px;
    /* position: relative;
    top: 6px; */
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
}
.show_icons_project{
    width: 36px;
    height: auto;
    position: relative;
    top: 2px;
}
.preview_project{
    display: none;
    opacity: 0;
}
.icons_project:hover .preview_project{
    display: block;
    opacity: 1;
}
.show_icons_project{
    width: 36px;
    height: auto;
    position: relative;
    top: 2px;
}


/* status */
.btn_status {
    border-radius: 40px;
    cursor: pointer;
    padding: 6px 10px;
    text-align: center;
    color: #ffffff;
}
.status_1{
    background-color: #c4c4c6;
    color: #ffffff;
}
.status_2{
    background-color: #0484c1;
    color: #ffffff;
}
.status_3{
    background-color: #037f4b;
    color: #ffffff;
}
.status_4{
    background-color: #7a4bcf;
    color: #ffffff;
}
.status_5{
    background-color: #01c875;
    color: #ffffff;
}
.status_6{
    background-color: #e3445a;
    color: #ffffff;
}
.status_7{
    background-color: #ffca00;
    color: #ffffff;
}
.status_8{
    background-color: #65ccff;
    color: #ffffff;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px rgba(22, 25, 28, 1);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px rgb(57, 61, 65);
    border-radius: 6px;
}
/* end */


/* table */
table{
    padding-bottom: 10px;
    width: 100%;
}
table th{
    font-family: "Poppins";
    font-weight: 700;
    font-size: 12px;
    color: #2D3C53;
    background-color: #E2E8F0;
    padding: 16px;
}
table tr{
    background-color: #ffffff;
}
table tr:nth-child(2n){
    background-color: #F6FAFF;
}
table td{
    padding: 16px;
    font-size: 12px;
}
table th:nth-child(-n + 1){
    border-top-left-radius: 8px;
}
table th:nth-last-child(-n + 1){
    border-top-right-radius: 8px;
}
table tr:nth-last-child(-n + 1) td:nth-child(-n + 1){
    border-bottom-left-radius: 8px;
}
table tr:nth-last-child(-n + 1) td:nth-last-child(-n + 1){
    border-bottom-right-radius: 8px;
}
/* end */


/* input file */
.new_file_add{
    background-color: #c9b174;
    padding: 10px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
    min-width: 150px;
    text-align: center;
    font-size: 12px;
}
.new_file_add:hover, .new_file_add_active{
    background-color: #996e00;
}
.div_add_img{
    display: flex !important;
    align-items: center !important;
    gap: 3px !important;
}
.div_add_img .delete_img_add{
    position: relative;
    margin-left: -10px;
    background-color: #2D3C53;
    padding: 7px;
    color: #FFFFFF;
    cursor: pointer;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.new_file_add label{
    cursor: pointer;
}
.new_file_add input[type="file"]{
    display: none;
}
/* end */


/* box text */
.jodit-react-container{
    width: -webkit-fill-available;
    text-align: left;
}
.jodit-wysiwyg p{
    padding: 0 !important;
    margin: 0 !important;
    font-family: "inter";
}
.jodit-status-bar{
    display: none;
}
.jodit-add-new-line, .jodit-add-new-line_after, .jodit-dialog__header-toolbar{
    display: none !important;
}
.jodit-dialog__panel{
    width: 380px !important;
}
.jodit-ui-button_keep, .jodit-ui-button_insert_as_text, .jodit-ui-button_text-icons_true, .jodit-status-bar{
    display: none !important;
}
.jodit-toolbar__box{
    background-color: #EEF1F5 !important;
    border-bottom: none !important;
}
.jodit-container:not(.jodit_inline){
    border-color: #EEF1F5 !important;
}
.jodit-ui-button_insert_only_text{
    background-color: #7c9f06 !important;
    color: #FFFFFF !important;
    display: block !important;
}
.jodit-ui-button_insert_only_text > span{
    display: none !important;
}
.jodit-ui-button_insert_only_text::after{
    content: "Copiar somente o texto!";
}
.jodit-dialog__content{
    display: none !important;
}
.jodit-dialog_footer_true .jodit-dialog__footer{
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt{
    width: -webkit-fill-available;
    text-align: center;
    max-width: 100% !important;
    height: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jodit-dialog_prompt > label{
    display: none !important;
}
.jodit-wysiwyg p img{
    vertical-align: middle;
}
.type_free{
    background-color: #9eb7d3;
    padding: 6px 10px;
    width: 60px;
    border-radius: 6px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.type_free_active{
    background-color: #324d6b;
}
/* end */


/* Select seatch */
.css-13cymwt-control{
    border: 2px solid #ebebeb !important;
    font-family: 'Inter' !important;
    padding: 2px;
}
.css-1nmdiq5-menu{
    z-index: 999 !important;
}
.css-1fdsijx-ValueContainer{
    padding: 0px 8px;
}
.css-1hb7zxy-IndicatorsContainer{
    max-height: 32px!important;
}
.css-1xc3v61-indicatorContainer{
    padding: 0px;
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 2px solid #324d6b;
    height: fit-content;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .div_input{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
.PopUp .button {
    padding: 0px 20px 20px;
}
.PopUp .button_save {
    background-color: #76c57e;
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .register_data{
    background-color: #7c9f06;
    width: auto;
    padding: 6px 10px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 16px;
}
.PopUp .opt_select{
    cursor: pointer;
    width: 120px;
    color: #ffffff;
}
.PopUp .yes_update {
    background-color: #76c57e;
    padding: 6px 10px;
    border-radius: 8px;
}
.PopUp .not_update {
    background-color: #e95656;
    padding: 6px 10px;
    border-radius: 8px;
}
/* end */


/* loading */
.return_data_save{
    position: absolute;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.return_data_save .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.return_data_save .dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
}
.return_data_save .dot:last-child {
    margin-right: 0;
}
.return_data_save .dot:nth-child(1) {
    animation-delay: -0.3s;
}
.return_data_save .dot:nth-child(2) {
    animation-delay: -0.1s;
}
.return_data_save .dot:nth-child(3) {
    animation-delay: 0.1s;
}
@keyframes pulse {
    0% {
        transform: scale(0.8);
        background-color: #b3d4fc;
        box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
    50% {
        transform: scale(1.2);
        background-color: #6793fb;
        box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }
    100% {
        transform: scale(0.8);
        background-color: #b3d4fc;
        box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
}
/* end */


/* switch */
.switch{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
}
.switch .switch_wrapper{
    display: inline-block;
    width: 40px;
    height: 14px;
    position: relative;
}
.switch .switch_wrapper .switch_button{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    background-color: #9d9d9d;
}
.switch .switch_wrapper input{
    opacity: 0;
    width:0px;
    height:0px
}
.switch .switch_wrapper .switch_button::before{
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    left: -1px;
    top: -4px;
    bottom: 3px;
    border-radius: 50%;
    transition: .4s all ease;
    background-color: #FFFFFF;
    box-shadow:
        rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px,
        rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.switch .switch_wrapper .switch_button:hover:before{
    box-shadow:
        rgb(38 38 38 / 20%) 0px 0px 0px 0px,
        rgb(38 38 38 / 14%) 0px 0px 6px 8px,
        rgb(38 38 38 / 12%) 0px 1px 3px 0px;
}
.switch .switch_wrapper input:checked + .switch_button{
    background-color: #9ae3ac;
}
.switch .switch_wrapper input:checked + .switch_button::before{
    transform:translateX(20px);
    background-color: #34c759;
}
.switch .switch_wrapper input:checked +.switch_button:hover:before {
    box-shadow:
        rgb(79 70 229 / 20%) 0px 0px 0px 0px,
        rgb(79 70 229 / 14%) 0px 0px 6px 8px,
        rgb(79 70 229 / 12%) 0px 1px 3px 0px
}
/* end */


/* input */
input, select, textarea{
    border: 2px solid #ebebeb;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: -webkit-fill-available;
    background-color: #ffffff;
}
.div_input {
    position: relative;
    margin-top: 10px;
}
.div_input_login {    
    margin-top: 20px;
}
.name_input{
    position: absolute;
    left: 10px;
    padding: 0px 4px;
    background-color: #ffffff;
    top: -10px;
    font-style: italic;
}
/* end */


/* input search */
.list_opt_alt_page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #E51D4D;
}
.new_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E2E8F0;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 40px;
}
.list_opt_alt_page .new_data {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
    font-weight: 600;
}
.new_block .div_name_page{
    font-size: 12px;
    font-family: 'Poppins';
    display: flex;
    align-items: center;
}
.new_block .div_name_page .icons{
    width: 20px;
    height: 20px;
}
.new_block .div_name_page .name_fixed{
    color: #6a6a6a;
}
.new_block .div_name_page .name_page{
    color: #ffffff;
    font-weight: 600;
}
.new_block .input_search, .input_search {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}
.new_block .icons_search, .icons_search{
    position: absolute;
    top: 11px;
    left: 8px;
    height: 20px;
    width: 20px;
    transition: 0.5s;
}
.new_block .search, .search{
    background-color: #CDD1D6;
    border: 2px solid #CDD1D6;
    border-radius: 8px;
    padding: 10px 10px 10px 30px;
    width: 200px;
}
.new_block ::placeholder{
    font-weight: 600;
}
.new_block .search:valid ~ .new_block .span_search_input, .new_block .search:focus ~ .new_block .span_search_input{
    transform: translateX(0px) translateY(-22px);
    background-color: #ffffff;
    padding: 0 10px;
}
.new_block .search:valid, .new_block .search:focus {
    border: 2px solid #CDD1D6;
}
.new_block .highlight_title_page{
    font-family: 'bebas Neue';
    font-size: 24px;
}
.new_block .new_block_text, .new_block_text {
    background-color: #E51D4D;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 6px;
    padding: 6px 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
    border: transparent;
}
.new_block .new_block_text:hover, .new_block_text:hover {
    background-color: #C42B79;
}
.new_block .new_data{
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
    font-size: 12px;
}
.new_block .icons_menu{
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
}
.new_block .page_return{
    cursor: pointer;
}
@media only screen and (max-width: 1024px) {
    .new_block .search, .search{
        width: 170px;
    }
}
/* end */


/* list pages */
.list_pages{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Inter';
    font-size: 16px;
    color: #ffffff;
    margin-top: 30px;
}
.list_pages .numb_page{
    background-color: #111827;
    padding: 2px 12px;
    border-radius: 4px;
    cursor: pointer;
}
.list_pages .numb_page:hover{
    background-color: #4a566e;
}
.list_pages .page_active {
    background-color: #a9b9cb;
}
/* end */


/* show page data */
.div_title_data {
    padding: 20px 0px 6px;
    font-size: 20px;
    color: #606875;
}
.show_page_data{
    width: -webkit-fill-available;
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
}
.show_page_data:nth-child(1n){
    margin-top: 20px;
}
.show_page_data .list_input_data{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.show_page_data .div_subtitle_add{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.show_page_data .list_input_data:nth-child(1n){
    margin-top: 6px;
}
.show_page_data .list_input_data:nth-last-child(-n + 1){
    margin-bottom: 10px;
}
.show_page_data .list_inf_data{
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    padding: 10px;
}
.show_page_data .list_type_data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 10px;
    margin-bottom: 16px;
}
.show_page_data .type_title{
    font-size: 16px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: center;
}
.show_page_data .new_data_page{
    color: #ffffff;
    padding: 6px 14px;
    border-radius: 6px;
    background-color: #7c9f06;
    cursor: pointer;
}
.show_page_data .new_data_page:hover {
    background-color: #008562;
}
.show_page_data td:nth-child(-n + 1){
    border-left: 1px solid #E2E8F0;
}
.show_page_data td:nth-last-child(-n + 1){
    border-right: 1px solid #E2E8F0;
}
.show_page_data tr:nth-last-child(-n + 1) td:nth-child(n){
    border-bottom: 1px solid #E2E8F0;
}
.show_page_data .show_data_text{
    background-color: #fff;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    padding: 10px;
}
.show_page_data .show_title {
    margin-bottom: 4px;
    font-weight: 500;
}
.show_page_data .add_project {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-content: center;
    width: 100%;
    border: 2px dashed #cdd1d6;
    border-radius: 8px;
}
.show_page_data .show_data_project {
    overflow-x: initial;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
}
.show_page_data .add_data {
    display: flex;
}
.show_page_data .div_restricted {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    color: #f00000;
    font-weight: 600;
    width: -webkit-fill-available;
    margin-bottom: 10px;
}
.show_page_data .div_restricted_lock{
    width: 24px;
    display: flex;
}
.show_page_data .div_restricted_title{
    flex-grow: 1;
    color: #606875;
}
.show_page_data .div_restricted_switch{
    width: 40px;
}
/* end */


/* show category and projects */
.list_category{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.list_category .show_name_category{
    font-family: 'Inter';
    font-weight: 700;
    font-size: 14px;
    padding: 10px 24px;
    line-height: 15px;
    color: #606875;
    border: 1.5px solid #606875;
    border-radius: 8px;
    cursor: pointer;
}
.list_category .show_name_category:hover, .list_category .category_active{
    color: #FFFFFF;
    background-color: #606875;
}

.list_portfolio_new_proposal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}
.list_portfolio_new_proposal .list_project{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.list_portfolio_new_proposal .div_project_data{
    position: relative;
    width: 238px;
    max-width: 280px;
    height: 140px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #2d3c53;
    flex-grow: 1;
}
.list_portfolio_new_proposal .project_data_inf{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}
.list_portfolio_new_proposal .project_data_client{
    color: #ffffff;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    font-size: 16px;
    font-weight: 600;
}
.list_portfolio_new_proposal .project_data_client .name_project{
    font-size: 8px;
    line-height: normal;
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
}
.list_portfolio_new_proposal .project_data_show_opt{
    color: #ffffff;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    z-index: 4;
}
.list_portfolio_new_proposal .project_data_lock_close{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 1;
    background-color: rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: all 2s ease;
}
.list_portfolio_new_proposal .project_data_div_img{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 32px;
}
.list_portfolio_new_proposal .project_data_img{
    width: 100%;
    height: 100%;
}
.list_portfolio_new_proposal .div_project_data:hover .project_data_lock_close{
    opacity: 0;
    transition: all 0.6s ease;
}
.list_portfolio_new_proposal .div_project_data:hover .project_data_inf{
    opacity: 1;
    transition: all 0.6s ease;
    background-color: rgb(0 0 0 / 40%);
    box-shadow: 0px 8px 13px 16px rgb(0 0 0 / 40%);
}
.list_portfolio_new_proposal .div_project_data:hover .project_data_client, .list_portfolio_new_proposal .div_project_data:hover .project_data_show_opt{
    opacity: 1;
    transition: all 0.6s ease;
    background-color: rgb(45 60 83 / 80%);
}
.list_portfolio_new_proposal .div_project_data .select_project_data{
    opacity: 1;
    background-color: rgb(45 60 83 / 80%);
}
.list_portfolio_new_proposal .div_project_data .hide_project_data{
    display: none;
}
.list_portfolio_new_proposal .project_data_opt_select{
    position: absolute;
    right: 10px;
    top: 8px;
}
.list_portfolio_new_proposal .div_project_data .circle_selected{
    width: 24px;
    height: 24px;
    display: block;
}
.list_portfolio_new_proposal .lock {
    width: 50px;
    height: 50px;
    display: block;
}
/* end */


/* show details */
.show_data_register{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: hidden;
    gap: 10px;
}
.show_data_register .data_project{
    flex-grow: 1;
    overflow-x: auto;
}
.show_data_register .data_fixed{
    width: 280px;
    min-width: 280px;
    max-width: 280px;
}
/* end */


/* div select */
.div_show_select{
    width: -webkit-fill-available;
    position: relative;
}
.div_show_select_height{
    height: 37px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0px 10px;
    border: 2px solid #ebebeb;
    border-radius: 6px;
    z-index: 10;
    position: relative;
}
.div_show_select_opt_selected{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}
.div_show_select_icons{
    border-left: 1px solid #CDD1D6;
    padding-left: 4px;
}
.div_show_select_name_data{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.div_show_select_list_data{
    position: absolute;
    margin-top: 8px;
    border: 2px solid #ebebeb;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 10px;
    border-radius: 6px;
    width: -webkit-fill-available;
    background-color: #ffffff;
    z-index: 20;
    max-height: 234px;
    overflow-y: auto;
}
.div_show_select_name{
    font-size: 12px;
    border-top: 2px solid #CDD1D6;
    width: 100%;
    cursor: pointer;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding: 10px 0px;
}
.div_show_select_new_data{
    text-transform: uppercase;
    color: #E51D4D;
    border-top: none;
    font-weight: 600;
    width: -webkit-fill-available;
}
.div_show_select_cancel{
    justify-content: end;
    border-top: 2px solid #CDD1D6;
}
.div_category_list{
    width: -webkit-fill-available;
    margin-top: 16px;
}
.div_category_list_no_data{
    color: #CDD1D6;
    font-size: 12px;
    text-align: center;
}
.div_category_list_name{
    flex-grow: 1;
    font-size: 12px;
}
.div_category_list_name_category{
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    height: auto;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 4px;
    position: relative;
}
.div_category_list_name_category:nth-last-child(-n + 1){
    border: none;
}
.show_color{
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.div_category_list_icons{
    width: 12px;
    height: 12px;
    cursor: pointer;
    display: block;
}
.div_category_list_remuve{
    font-size: 16px;
    text-transform: uppercase;
    color: #E51D4D;
    font-weight: 600;
    padding-right: 10px;
}
.div_category_list_name_category:hover{
    background-color: rgba(238, 241, 245, 0.5);
    border-radius: 6px;
}
.div_category_title{
    font-weight: 600;
    color: #606875;
}
.div_category_search{
    margin-top: 10px;
}
.div_show_select_list_data .add_new_data{
    width: -webkit-fill-available;
    position: relative;
}
.div_show_select_list_data .show_data_existing{
    width: -webkit-fill-available;
    position: relative;
    overflow-y: auto;
    margin-top: 0px;
}
.div_show_select_list_data .close_list_data{
    width: -webkit-fill-available;
    position: relative;
}
.div_show_text .div_contact{
    background-color: rgba(229, 29, 77, 0.1);
    border-radius: 6px;
    padding: 6px 0px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.div_show_text .new_contact{
    background-color: #E51D4D;
    border-radius: 20px;
    width: max-content;
    padding: 4px;
}
/* end */


/* div show or new data */
.div_show_or_new_data{
    margin-top: 6px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: -webkit-fill-available;
    gap: 10px;
    
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 2px solid #cdd1d6;
}
.div_show_or_new_data:nth-last-child(-n+1){    
    padding-bottom: 0px;
    border-bottom: none;
}
.div_show_or_new_data .div_selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-right: 8px;
}
.div_show_or_new_data .div_selected .show_type_selected {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f00000;
    border-radius: 24px;
    text-align: center;
    color: #ededed;
}
.div_show_or_new_data .title_block{
    font-size: 20px;
    font-weight: 500;
}
.div_show_or_new_data .add_data {
    display: flex;
}
.div_show_or_new_data .show_data_add {
    flex-direction: column;
    padding: 20px 10px 10px;
    background-color: #ffffff;
    border-radius: 6px;
    border: 2px solid #ebebeb;
}
.div_show_or_new_data .div_data_project {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 10px;
}
.div_show_or_new_data .space_show_data{
    padding: 0px;
    margin-top: 8px;
}
.div_show_or_new_data .list_opt_add{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-fill-available;
    gap: 20px !important;
}
.div_show_or_new_data .show_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: -webkit-fill-available;
    gap: 10px;
    padding-bottom: 0px;
    font-weight: 500;
}
.div_show_or_new_data .title_div{
    font-weight: 600;
    font-size: 16px;
    color: inherit;
}
.div_show_or_new_data .div_data_topic_project{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.div_show_or_new_data .div_switch{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-left: 1px solid #cdd1d6;
    padding-left: 20px;
    margin-left: 20px;
}
.div_show_or_new_data .align_type_contents{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
.div_show_or_new_data .div_align{
    color: #E51D4D;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
}
.div_show_or_new_data .div_type_align{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.div_show_or_new_data .list_align{
    width: 94px;
    height: 94px;
    background-color: #EEF1F5;
    position: absolute;
    border: 2px solid #CDD1D6;
    border-radius: 4px;
    z-index: 2;
    top: 0px;
    right: 0;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.div_show_or_new_data .list_align_img{
    height: 36px;
}
.div_show_or_new_data .list_active{
    display: flex;
}

.div_show_or_new_data .align{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    gap: 2px;
}
.div_show_or_new_data .div_add_type{
    display: flex;
    width: -webkit-fill-available;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}
.div_show_or_new_data .list_content{
    padding: 10px;
    width: -webkit-fill-available;
}
.div_show_or_new_data .list_opt{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.div_show_or_new_data .show_opt{
    display: flex;
    width: 100px;
    height: 72px;
}
.div_show_or_new_data .opt_icon {
    width: 100%;
    height: 100%;
}
.div_show_or_new_data .div_opt_add{
    background-color: #6A85AE;
    color: #ffffff;
    border-radius: 6px;
    width: 90px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    min-width: 80px;
    position: relative;
}
.div_show_or_new_data .div_alt_data_contents{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 10px;
}
/* end */