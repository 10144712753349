.Contents{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}
.Contents .show_site{
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 888;
    background-color: #324d6b;
    padding: 10px;
    border-radius: 50%;
}
.Contents .icons_site{
    display: flex;
    width: 20px;
    height: 20px;
}
.Contents .list_menu {
    width: 244px;
    max-width: 244px;
    min-width: 244px;
    height: 100%;
    background-color: rgba(42, 49, 54, 1);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.2s;
}
.Contents .active_menu{
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    transform: translateX(0px) translateY(0px);
}
.Contents .name {
    font-size: 18px;
    color: #fff;
}
.Contents .button_edit {
    color: #ffffff;
    background-color: #7c9f06;
    padding: 6px 16px;
    cursor: pointer;
}
.Contents .opt_menu_dash {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.Contents .name_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -webkit-fill-available;
    font-size: 13px;
    gap: 10px;
}
.name_menu_{
    justify-content: center;
}
.Contents .name_submenu{
    display: none;
    flex-direction:column;
    width: -webkit-fill-available;
    padding-left: 30px;
    align-items: flex-start;
}
.Contents .submenu_active{
    display: flex;
}
.Contents .icons_active{
    transform: rotate(90deg);
}
.Contents .icons_submenu{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
.Contents .space_submenu{
    padding-left: 10px;
}
.Contents .show_menu_dash {
    padding: 10px 20px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    width: -webkit-fill-available;
}
.Contents .show_menu_dash:hover{
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transition: all 0.2s;
}
.Contents .active_dash, .Contents .active_dash:hover{
    background-color: rgba(7, 196, 214, 0.5);
    border-right: 4px solid rgba(255, 196, 0, 1);
    transform: none;
}
.Contents .show_menu_dash_{
    padding: 10px;
}
.Contents .div_icons{
    display: flex;
}
.Contents .icons_dash{
    width: 24px;
    height: 24px;
    display: block;
}
.Contents .list_data {
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    background-color: rgba(22, 25, 28, 1);
}
.Contents .show_data{
    width: -webkit-fill-available;
    /* background-color: #eef0f5; */
    margin: 20px;
    border-radius: 6px;
}
.Contents .no_data{
    text-align: center;
    font-size: 12px;
    width: -webkit-fill-available;
    margin-top: 20px;
}
.Contents .list_opt_add{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}
.Contents .list_opt_add_{
    flex-direction: column;
}
.Contents .show_option {
    align-items: center;
    background-color: #6a85ae;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    height: 106px;
    justify-content: center;
    width: -webkit-fill-available;
}
.Contents .opt_add_project{
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 10px;
}
.Contents .opt_add_project_{
    padding: 10px;
}

.Contents .show_opt_menu{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.Contents .div_logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #E2E8F0;
    height: 90px;
    cursor: pointer;
}
.Contents .div_logo_opt{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    padding: 10px 20px;
}
.Contents .div_logo_opt_{
    padding: 10px;
    justify-content: center;
}
.Contents .logotipo{
    width: 120px;
    height: auto;
    display: block;
}
.Contents .logotipo2{
    width: 30px;
    height: auto;
    display: block;
}

.Contents .div_profile{
    padding: 20px 10px;
}
.Contents .div_profile_data{
    cursor: pointer;
    background-color: rgba(106, 133, 174, 0.3);
    border-radius: 8px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
}
.Contents .div_profile_data_{
    padding: 0px;
    justify-content: center;
}
.Contents .menu_close{
    display: none;
}
.Contents .div_img{
    display: flex;
}
.Contents .show_img_profile{
    width: 32px;
    height: 32px;
    border-radius: 32px;
}
.Contents .name_user{
    color: #ffffff;
    font-family: 'inter';
    font-weight: bold;
    text-align: center;
}
