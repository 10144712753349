.Page_UsersSite{
    width: 100%;
    height: auto;
    padding-bottom: 70px;
}
.Page_UsersSite .icons{
    cursor: pointer;
}
.Page_UsersSite .icon_user{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
}
.Page_UsersSite .icon_site{
    width: 24px;
    height: 24px;
}
