.Page_Floorplans {
    padding-bottom: 70px;
}
.Page_Floorplans .div_icon_cover{
    display: flex;
}
.Page_Floorplans .div_icon_cover .icon_cover {
    width: auto;
    height: 32px;
    border-radius: 6px;
    cursor: pointer;
}