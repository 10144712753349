.PopUp .SizeIcon{
    width: 600px;
}
.PopUp .SizeIcon .size_column{
    flex-direction: column;
}
.PopUp .SizeIcon .content{
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.PopUp .SizeIcon .content .div_examplo{
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    border: 1px solid #324d6b;
    border-radius: 6px;
    padding: 10px;
}
.PopUp .SizeIcon .content .div_examplo .div_img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .SizeIcon .content .div_examplo .div_img .icons_img_1 {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .SizeIcon .content .div_examplo .div_img .icons_img_2 {
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .SizeIcon .content .div_examplo .div_img .icons_img_3 {
    width: auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .SizeIcon .content .div_examplo .name_opt{
    border: 1px solid #7c9f06;
    border-radius: 6px;
    padding: 6px 14px;
    cursor: pointer;
}
.PopUp .SizeIcon .content .div_examplo .name_opt:hover{
    color: #ffffff;
    background-color: #008562;
    border: 1px solid #008562;
}
.PopUp .SizeIcon .content .div_examplo .name_opt_active{
    color: #ffffff;
    background-color: #7c9f06;
    border: 1px solid #7c9f06;
}

.PopUp .SizeIcon .register_data{
    border-radius: 6px;
}