.Page_Gallery_Details{
    padding-bottom: 70px;
}

.Page_Gallery_Details .list_input_data{
    justify-content: space-between;
}
.Page_Gallery_Details .show_list_file .div_add_img{
    justify-content: flex-end;
    margin-bottom: 10px;
}

.Page_Gallery_Details .list_opt_point .list_input_data{
    justify-content: flex-start;
}

.Page_Gallery_Details .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_Gallery_Details .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}