.PopUp .show_file_two {
    width: 760px;
}
.PopUp .show_file_two .show_img{
    max-width: 100%;
    max-height: 600px;
}
.PopUp .show_file_two .div_video{
    width: -webkit-fill-available;
    height: 400px;
}

.PopUp .show_file_two .add_fixed{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    color: #ffffff;
    font-size: 16px;
}
.PopUp .show_file_two .add_fixed .btn{
    background-color: #7c9f06;
    padding: 6px 20px;
    width: max-content;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'inter';
    font-size: 14px;
}
.PopUp .show_file_two .add_fixed .img_selected{
    background-color: #E51D4D;
}

.PopUp .show_file_two .div_arrow{
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 4;
}
.PopUp .show_file_two .div_arrow .space_white{
    width: 760px;
}
.PopUp .show_file_two .div_arrow .arrow .icons{
    width: 100px;
    height: 100px;
}
.PopUp .show_file_two .div_arrow .arrow .icons_left{
    transform: rotate(180deg);    
}