.Page_MapPoi{
    overflow: hidden;
}
.Page_MapPoi .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_MapPoi .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}
.Page_MapPoi .show_map{
    width: calc(100% - 40px);
    height: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    position: relative;
    gap: 16px;
}
.Page_MapPoi .show_map .list_btn_category{
    background: #e0cab5;
    width: 170px;
    height: 100%;
}
.Page_MapPoi .show_map .list_btn_category .title{
    height: 36px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #76c57e;
    cursor: default;
}
.Page_MapPoi .show_map .list_btn_category .name_category{
    height: 36px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #2D3C53;
    cursor: pointer;
}
.Page_MapPoi .show_map .list_btn_category .name_category:nth-last-child(-n+1){
    border-bottom: none;
}
.Page_MapPoi .show_map .list_btn_category .name_category:hover{
    color: #ffffff;
    background: #2D3C53;
}
.Page_MapPoi .show_map .list_btn_category .category_active{
    color: #ffffff;
    background-color: #7c9f06;
}
.Page_MapPoi .show_map .div_show_map{
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}
.Page_MapPoi .icon_map .icons{
    width: auto;
    height: 34px;
}
.Page_MapPoi .show_point{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    position: relative;
}
.Page_MapPoi .show_point .img_point{
    display: flex;
    width: 24px;
}
.Page_MapPoi .show_point .opt_1 {
    width: auto;
    height: 32px;
}
.Page_MapPoi .show_point .opt_2 {
    width: auto;
    height: 60px;
}
.Page_MapPoi .show_point .opt_3 {
    width: auto;
    height: 100px;
}

.Page_MapPoi .show_point .text{
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    width: auto;
    opacity: 0;
    text-shadow: #a6a58f 0px 0px 10px;
}
.Page_MapPoi .show_point:hover .text{  
    animation: anim_text 0.5s linear;
    animation-fill-mode: forwards;
}
@keyframes anim_text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.Page_MapPoi .show_data_text{
    position: relative;
    margin-top: 10px;
}
.Page_MapPoi .show_data_text:nth-child(n+2){
    margin-top: 30px;
}
.Page_MapPoi .show_data_text .position_icon{
    position: absolute;
    padding: 0px 10px;
    top: -12px;
    background-color: #ffffff;
}


.Page_MapPoi #map{
    width: 100%;
    height: 100%;
}
.Page_MapPoi #map .icons_teste{
    width: 24px;
    height: auto;
}
.Page_MapPoi .zoom{
    width: 80px;
    text-align: center;
}
.Page_MapPoi .show_icon{
    width: 110px;
}
.Page_MapPoi .space_top{
    margin-top: 20px !important;
}
.Page_MapPoi .size_icon{
    margin-top: 6px !important;
    margin-bottom: 0px !important;
}
.Page_MapPoi .type_icon .icons{
    width: 24px;
    height: auto;
}