.PopUp .add_point_map{
    width: 600px;
}

.PopUp .add_point_map .list_input_data {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.PopUp .add_point_map .opt_select{
    flex-grow: 1;
    margin-top: 20px;
}
.PopUp .add_point_map .div_input{
    width: auto;
}
.PopUp .add_point_map .show_img_{
    width: 32px;
    height: 32px;
}


.PopUp .add_point_map .content .div_examplo{
    width: 160px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    border: 1px solid #324d6b;
    border-radius: 6px;
    padding: 10px;
    margin-top: 16px;
}
.PopUp .add_point_map .content .div_examplo .div_img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .add_point_map .content .div_examplo .div_img .icons_img_1 {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .add_point_map .content .div_examplo .div_img .icons_img_2 {
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .add_point_map .content .div_examplo .div_img .icons_img_3 {
    width: auto;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .add_point_map .content .div_examplo .name_opt{
    border: 1px solid #7c9f06;
    border-radius: 6px;
    padding: 6px 14px;
    cursor: pointer;
}
.PopUp .add_point_map .content .div_examplo .name_opt:hover{
    color: #ffffff;
    background-color: #008562;
    border: 1px solid #008562;
}
.PopUp .add_point_map .content .div_examplo .name_opt_active{
    color: #ffffff;
    background-color: #7c9f06;
    border: 1px solid #7c9f06;
}

.PopUp .add_point_map .register_data{
    border-radius: 6px;
}

.PopUp .add_point_map .btn_save{
    border: transparent;
    font-size: 15px;
    padding: 8px 10px;
    width: 140px;
}