.Login{
    width: 100%;
    height: 100%;
    background-color: #16191C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Login .div_data_login{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Login .div_data_login .show_data_login{
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Login .div_data_login .show_data_login .div_logotipo{
    display: flex;
}
.Login .div_data_login .show_data_login .div_logotipo .logotipo{
    width: 168px;
}
.Login .div_data_login .show_data_login .div_text{
    font-weight: 400;
    padding: 20px 0px;
}
.Login .div_data_login .show_data_login .div_input{
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
}
.Login .div_data_login .show_data_login .div_input input{
    background: rgba(0, 0, 0, 0);
    border: 1px solid rgba(255, 255, 255, 0.32);
    border-radius: 30px;
    box-shadow: inset 0 0 10px rgb(255 255 255 / 25%);
    color: #ffffff;
    padding: 16px;
}
.Login .div_data_login .show_data_login .div_input input::placeholder {
    color: #ffffff;
}
.Login .div_data_login .show_data_login .type_save{
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.Login .div_data_login .show_data_login .type_save .div_save{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}
.Login .div_data_login .show_data_login .type_save .div_save .input_checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Login .div_data_login .show_data_login .type_save .div_save .input_checkbox .checkbox{
    width: 16px;
    height: 16px;
}
.Login .div_data_login .show_data_login .type_save .div_recover{
    cursor: pointer;
}
.Login .div_data_login .show_data_login .div_logar{
    display: flex;
    flex-direction: row;
    background: rgba(245, 154, 64, 1);
    border-radius: 30px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    font-size: 14px;
}
.Login .div_data_login .show_data_login .div_logar .div_btn{
    color: #0C0507;
    display: flex;
    width: 122px;
    height: 48px;
    align-items: center;
    justify-content: center;
}

.Login .div_img_bg{
    width: auto;
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Login .div_img_bg .img_bg_login{
    display: flex;
    width: auto;
    height: 100%;
}